<template>
  <div>
    <sdPageHeader :title="DealId ? 'Edit Supplier Quotation' : 'Add Supplier Quotation'">
      <template v-slot:buttons>
        <div class="page-header-actions">
          <sdButton v-if="DealId && $userHasPermission('Permissions.Deal.Update')" @click="handleChangeReadMode"
            size="small" type="primary" style="gap: 5px">
            {{ readOnlyMode ? "Edit" : "Read only" }}
            <sdFeatherIcons :type="readOnlyMode ? 'edit' : 'eye'" size="14" />
          </sdButton>
          <sdButton v-if="DealId" size="small" type="white" @click="toggelHistory()">
            <span>{{ $t('history') }}</span>
            <sdFeatherIcons type="clock" />
          </sdButton>
        </div>
      </template>
    </sdPageHeader>
    <Main>
      <a-form class="main-form" name="sDash_validation-form" ref="formRef" :rules="rules" @finish="onSubmit"
        :model="formState" :layout="formState.layout">
        <a-row :gutter="25">
          <a-col :xs="24">
            <sdCards title="Virtuel stock details" caption="Virtuel stock details">
              <FormValidationWrap>
                <HorizontalFormStyleWrap>
                  <a-row :gutter="30">
                    <a-col :span="6" :xs="6">
                      <a-form-item name="date" label="Date">
                        <a-date-picker :disabled="DealApiloading || readOnlyMode" v-model:value="formState.date"
                          :style="{ width: '100%' }" :format="dateFormat" />
                      </a-form-item>
                    </a-col>
                    <a-col :span="6" :xs="6">
                      <a-form-item name="validityDate" label="Validity (days)">
                        <a-input-number :disabled="DealApiloading || readOnlyMode" v-model:value="formState.validityDate"
                          placeholder="Validity" :style="{ width: '100%' }" />
                      </a-form-item>
                    </a-col>
                    <a-col :span="6" :xs="6">
                      <a-form-item label="Partner" name="partenaireId">
                        <a-select v-if="partnerList?.length" :disabled="DealApiloading || readOnlyMode"
                          v-model:value="formState.partenaireId" placeholder="Please choose partner" show-search
                          style="width: 100%" :options="partnerData" :not-found-content="partnerFetching ? undefined : null
                            " :filter-option="false" @search="searchPartner">
                          <template v-if="partnerFetching" #notFoundContent>
                            <a-spin size="small" />
                          </template>
                        </a-select>
                        <a-select v-else :disabled="DealApiloading || readOnlyMode" v-model:value="formState.partenaireId"
                          placeholder="Please choose partner">
                        </a-select>
                      </a-form-item>
                    </a-col>
                    <a-col :span="6" :xs="6">
                      <a-form-item name="number" label="Number">
                        <a-input-number class="custom" disabled v-model:value="formState.number"
                          :style="{ width: '100%' }" />
                      </a-form-item>
                    </a-col>
                    <a-col :span="6" :xs="6">
                      <a-form-item label="VAT" name="saleStatus">
                        <a-input-number :disabled="readOnlyMode" :style="{ width: '100%' }" :min="0" :max="100"
                          :formatter="(value) => `${value}%`" :parser="(value) => value.replace('%', '')"
                          v-model:value="formState.vat" placeholder="Value" />
                      </a-form-item>
                    </a-col>
                    <a-col :span="6" :xs="6">
                      <a-form-item label="Possibility of partial sale" name="partialSale">
                        <a-select v-model:value="formState.partialSale" :disabled="readOnlyMode"
                          placeholder="is partial sale ?">
                          <a-select-option :value="1">Not Defined</a-select-option>
                          <a-select-option :value="2">Yes</a-select-option>
                          <a-select-option :value="3">No</a-select-option>
                        </a-select>
                      </a-form-item>
                    </a-col>
                    <a-col :span="6" :xs="6">
                      <a-form-item name="offeredBy" label="Offered by">
                        <a-input class="custom" v-model:value="formState.offeredBy" :disabled="readOnlyMode"
                          :style="{ width: '100%' }" />
                      </a-form-item>
                    </a-col>
                    <a-col :span="6" :xs="6">
                      <a-form-item name="reference" label="Reference">
                        <a-input class="custom" v-model:value="formState.reference" :disabled="readOnlyMode"
                          :style="{ width: '100%' }" />
                      </a-form-item>
                    </a-col>
                    <a-col :span="8">
                      <a-form-item ref="files" label="Supplier Offer" name="SupplierOffer">
                        <a-upload v-if="!readOnlyMode" v-model:file-list="formState.files" name="SupplierOffer"
                          :multiple="true" :customRequest="uploadDealfiles" :remove="removeFile"
                          :style="{ width: '100%' }" :showUploadList="false">
                          <a-button class="btn-outlined" size="large" :outlined="true">
                            <upload-outlined></upload-outlined>
                            <span>Select Files</span>
                          </a-button>
                        </a-upload>
                        <div v-for="file in DealFileList" :key="file.uid" class="file-item">
                          <span class="file-text" :title="file.name">{{ file.name }}</span>
                          <span @click="handleDownload(file)">
                            <sdFeatherIcons type="download" />
                          </span>
                          <a-popconfirm title="Sure to delete file?" @confirm="removeFile(file)">
                            <span>
                              <sdFeatherIcons type="trash-2" />
                            </span>
                          </a-popconfirm>
                        </div>
                      </a-form-item>
                    </a-col>
                    <a-col :offset="18" :span="6" class="button-container" v-if="!readOnlyMode">
                      <sdButton htmlType="onSubmit" key="onSubmit" class="btn-outlined ant-btn-lg client-form-submit"
                        outlined type="primary">
                        <span>Save</span>
                      </sdButton>
                    </a-col>
                  </a-row>
                </HorizontalFormStyleWrap>
              </FormValidationWrap>
            </sdCards>
          </a-col>
          <a-col class="w-100" :md="10">
          </a-col>
        </a-row>
      </a-form>
      <a-row :gutter="25">
        <a-col :xs="24">
          <sdCards title="Products" caption="Products">
            <template #button>
              <a-dropdown v-if="DealId && !readOnlyMode" class="add-product">
                <template #overlay>
                  <a-menu>
                    <a-menu-item @click="showDrawer(OCTG)" key="1">OCTG</a-menu-item>
                    <a-menu-item @click="showDrawer(OTHER)" key="2">OTHER</a-menu-item>
                  </a-menu>
                </template>
                <a-button type="primary">
                  Add a new Product
                  <DownOutlined />
                </a-button>
              </a-dropdown>
            </template>
            <TableWrapper class="table-data-view table-responsive">
              <a-table :pagination="{ pageSize: 10, showSizeChanger: true }" :dataSource="productsData" :columns="columns"
                :loading="ProductAreLoading">
                <template #expandedRowRender="{ record }">
                  <a-table class="inner-table" v-if="record.product.productType == 1" :columns="innerOCTGColumns"
                    :data-source="[
                      { ...record.product, key: record.product.id },
                    ]" :pagination="false">
                  </a-table>
                  <a-table class="inner-table" v-else :columns="innerOtherColumns" :data-source="[
                    { ...record.product, key: record.product.id },
                  ]" :pagination="false">
                  </a-table>
                </template>
                <template #footer>
                  <div class="table-footer">
                    Total NET : {{ totalNet }}
                  </div>
                  <div class="table-footer">
                    Total VAT : {{ totalVat.toFixed(2) }}
                  </div>
                  <div class="table-footer">
                    Total TTC : {{ totalTtc }}
                  </div>
                </template>
              </a-table>
            </TableWrapper>
          </sdCards>
        </a-col>
      </a-row>
      <a-row :gutter="25">
        <a-col :xs="24">
          <AuxiliaryCharge parentType="3" ref="auxiliaryChargeCmp" :readOnlyMode="readOnlyMode" :parentId="DealId">
          </AuxiliaryCharge>
        </a-col>
      </a-row>
      <a-drawer placement="right" :closable="true" @close="closeDrawer" v-model:visible="productForm.visible"
        :title="!productForm.editionMode ? 'Add product' : 'Edit product'" type="custom" :width="'fit-content'">
        <a-row class="deal-info">
          <a-col :span="6">
            <a-statistic title="Quotation date" :value="dealFormatedDate" style="margin-right: 50px" />
          </a-col>
          <a-col :span="6">
            <a-statistic title="Validity (days)" :value="formState.validityDate" />
          </a-col>
        </a-row>
        <a-form ref="productFormRef" @finish="onSubmitProduct" :model="productForm" :rules="ProductRules"
          layout="vertical">
          <a-row :gutter="25">
            <a-col :span="24">
              <a-form-item label="Product Descirption" v-if="productIsOther">
                <a-textarea v-model:value="productForm.note" :style="{ width: '100%' }"
                  placeholder="Product Descirption ..." name="note" class="sDash_unresizable" />
              </a-form-item>
            </a-col>
            <a-col :span="6" v-if="productIsOther">
              <a-form-item label="HS code" name="hsCodeId">
                <a-select :size="'small'" v-model:value="productForm.hsCodeId" placeholder="HS Code" show-search
                  style="width: 100%" :options="hsCodeData" :not-found-content="hsCodeFetching ? undefined : null"
                  :filter-option="false" @search="searchHSCode">
                  <template v-if="hsCodeFetching" #notFoundContent>
                    <a-spin size="small" />
                  </template>
                </a-select>
              </a-form-item>
            </a-col>
            <a-col :span="6" v-if="!productIsOther">
              <a-form-item label="Category" name="categoryId">
                <a-select v-model:value="productForm.categoryId" placeholder="category">
                  <a-select-option v-for="(category, index) in CategoryApi" :key="index" :value="category.id">
                    {{ category.name }}</a-select-option>
                </a-select>
              </a-form-item>
            </a-col>
            <a-col :span="6" v-if="!productIsOther">
              <a-form-item label="Size" name="sizeId">
                <a-select v-model:value="productForm.sizeId" show-search placeholder="Select a size" style="width: 100%"
                  :options="sizeList" :filter-option="selectFilterOption" @change="SelectWeights">
                  <a-select-option v-for="(size, index) in sizeList" :key="index" :value="size.id">
                    {{ size.name }} - {{ size.mm }}mm</a-select-option>
                </a-select>
              </a-form-item>
            </a-col>
            <a-col :span="6" v-if="!productIsOther">
              <a-form-item label="Weight" name="weightId">
                <a-select :disabled="!productForm.sizeId" v-model:value="productForm.weightId" @change="getWeight"
                  placeholder="Weight" show-search style="width: 100%" :options="SizeWeight"
                  :filter-option="selectFilterOption"></a-select>
              </a-form-item>
            </a-col>
            <a-col :span="6" v-if="!productIsOther">
              <a-form-item label="Grade" name="gradeId">
                <a-select v-model:value="productForm.gradeId" show-search placeholder="Select a grade" style="width: 100%"
                  :options="gradeList" :filter-option="selectFilterOption"></a-select>
              </a-form-item>
            </a-col>
            <a-col :span="6" v-if="!productIsOther">
              <a-form-item label="Range" name="rangeId">
                <a-select v-model:value="productForm.rangeId" @change="getRangeCode" placeholder="Range">
                  <a-select-option v-for="(range, index) in RangeApi" :key="index" :value="range.id">
                    {{ range.name }}</a-select-option>
                </a-select>
              </a-form-item>
            </a-col>
            <a-col :span="6" v-if="!productIsOther">
              <a-form-item label="Thread" name="threadId">
                <a-select v-model:value="productForm.threadId" placeholder="Thread">
                  <a-select-option v-for="(thread, index) in ThreadApi" :key="index" :value="thread.id">
                    {{ thread.name }}</a-select-option>
                </a-select>
              </a-form-item>
            </a-col>
            <a-col :span="6">
              <a-form-item label="Incoterm" name="incotermId">
                <a-select v-model:value="productForm.incotermId" placeholder="Incoterm" show-search style="width: 100%"
                  :options="incotermList" :filter-option="selectFilterOption">
                </a-select>
              </a-form-item>
            </a-col>
            <a-col :span="6">
              <a-form-item ref="stockLocation" label="Place of Delivery or Collection" name="stockLocation">
                <a-input v-model:value="productForm.stockLocation" placeholder="Place of Delivery or Collection" />
              </a-form-item>
            </a-col>
            <a-col :span="6">
              <a-form-item ref="drift" :label="!productIsOther ? 'Drift': 'Approx.weight in Kg'" name="drift">
                <a-input v-model:value="productForm.drift" :placeholder="!productIsOther ? 'Drift' : 'Approx.weight in Kg'" />
              </a-form-item>
            </a-col>
            <a-col :span="6">
              <a-form-item ref="stockLocationMaps" label="Yard location (Address)" name="stockLocationMaps">
                <a-input :style="{ width: '100%' }" v-model:value="productForm.stockLocationMaps"
                  placeholder="Yard location (City, Country)" />
              </a-form-item>
            </a-col>
            <a-col :span="6">
              <a-form-item label="Country" name="countryId">
                <a-select v-model:value="productForm.countryId" show-search placeholder="Select a country"
                  style="width: 100%" :options="countryList" :filter-option="selectFilterOption"></a-select>
              </a-form-item>
            </a-col>
            <a-col :span="6">
              <a-form-item label="Product condition" name="condition">
                <a-select v-model:value="productForm.condition" placeholder="Product condition">
                  <a-select-option :value="1">Brand New</a-select-option>
                  <a-select-option :value="2">Unused, Inspected</a-select-option>
                  <a-select-option :value="4">Unused, Needs Refurbishment</a-select-option>
                  <a-select-option :value="3">Unused, Not Inspected</a-select-option>
                  <a-select-option :value="5">Used</a-select-option>
                  <a-select-option :value="7">Scrap</a-select-option>
                  <a-select-option :value="6">Not Defined</a-select-option>
                </a-select>
              </a-form-item>
            </a-col>
            <a-col :span="6">
              <a-form-item label="Availability" name="availability">
                <a-select v-model:value="productForm.availability" placeholder="Availability">
                  <a-select-option :value="1">Ex-stock</a-select-option>
                  <a-select-option :value="2">Ex-mill</a-select-option>
                </a-select>
              </a-form-item>
            </a-col>
            <a-col :span="6">
              <a-form-item label="Possibility of partial sale" name="isPartialSale">
                <a-select v-model:value="productForm.isPartialSale" placeholder="is partial sale ?">
                  <a-select-option :value="1">Not Defined</a-select-option>
                  <a-select-option :value="2">Yes</a-select-option>
                  <a-select-option :value="3">No</a-select-option>
                </a-select>
              </a-form-item>
            </a-col>
            <a-col :span="6" v-if="!productIsOther">
              <a-form-item label="PSL" name="pslId">
                <a-select v-model:value="productForm.pslId" placeholder="Please choose a PSL">
                  <a-select-option v-for="(pslId, index) in PslApi" :key="index" :value="pslId.id">
                    {{ pslId.name }}</a-select-option>
                </a-select>
              </a-form-item>
            </a-col>
            <a-col :span="6">
              <a-form-item label="Units of Measurement" name="unitOfMeasureId">
                <a-select v-model:value="productForm.unitOfMeasureId" @change="getUomCode"
                  placeholder="Please choose a unit">
                  <a-select-option v-for="(unit, index) in UnitApi" :key="index" :value="unit.id">
                    {{ unit.name }}</a-select-option>
                </a-select>
              </a-form-item>
            </a-col>
            <a-col :span="6">
              <a-form-item label="Quantity" name="quantityInitial">
                <a-input-number :style="{ width: '100%' }" v-model:value="productForm.quantityInitial"
                  @change="getJointsQuantity" placeholder="Quantity" />
              </a-form-item>
            </a-col>
            <a-col :span="6">
              <a-form-item label="Purchase Unit Price" name="priceForSales">
                <a-input-number :style="{ width: '100%' }" v-model:value="productForm.priceForSales"
                  placeholder="Purchase Unit Price" />
              </a-form-item>
            </a-col>
            <a-col :span="6">
              <a-form-item label="Total Purchase Price">
                <a-input-number disabled :style="{ width: '100%' }" v-model:value="totalPrice"
                  placeholder="Total Purchase Price " />
              </a-form-item>
            </a-col>
            <a-col :span="6">
              <a-form-item label="Currency" name="currencyId">
                <a-select v-model:value="productForm.currencyId" placeholder="currencyId">
                  <a-select-option v-for="(currency, index) in CurrencyApi" :key="index" :value="currency.id">
                    {{ currency.name }}</a-select-option>
                </a-select>
              </a-form-item>
            </a-col>
            <a-col :span="6">
              <a-form-item label="Country of Origin" name="originCountryId">
                <a-select v-model:value="productForm.originCountryId" show-search style="width: 100%"
                  :options="countryList" :filter-option="selectFilterOption"></a-select>
              </a-form-item>
            </a-col>
            <a-col :span="6">
              <a-form-item ref="deliveryTime" label="Delivery Time" name="deliveryTime">
                <a-input-number :style="{ width: '100%' }" v-model:value="productForm.deliveryTime"
                  placeholder="Delivery Time" />
              </a-form-item>
            </a-col>
            <a-col :span="6">
              <a-form-item label="Approx. Nbr of Pipes" name="numberPackage">
                <a-input-number :style="{ width: '100%' }" v-model:value="productForm.numberPackage"
                  @change="getJointsQuantity" placeholder="Approx. Nbr of Pipes" />
              </a-form-item>
            </a-col>
            <a-col :span="8">
              <a-form-item ref="PictureProduct" label="Photo(s)" name="PictureProduct">
                <a-upload v-model:file-list="PhotoFileList" name="PictureProduct" :customRequest="uploadfiles"
                  :multiple="true" :remove="removeFile" :style="{ width: '100%' }" :showUploadList="false">
                  <a-button class="btn-outlined" size="large" :outlined="true">
                    <upload-outlined></upload-outlined>
                    <span>upload photos</span>
                  </a-button>
                </a-upload>
                <div v-for="file in PhotoFileList" :key="file.uid" class="file-item">
                  <span class="file-text" :title="file.name">{{ file.name }}</span>
                  <a @click="handleDownload(file)">
                    <sdFeatherIcons type="download" />
                  </a>
                  <a @click="removeFile(file)">
                    <sdFeatherIcons type="trash-2" />
                  </a>
                </div>
              </a-form-item>
            </a-col>
            <a-col :span="8">
              <a-form-item ref="MTR" label="Certificates and MTRs" name="MTR">
                <a-upload v-model:file-list="MTRFileList" name="MTR" :multiple="true" :customRequest="uploadfiles"
                  :remove="removeFile" :style="{ width: '100%' }" :showUploadList="false">
                  <a-button class="btn-outlined" size="large" :outlined="true">
                    <upload-outlined></upload-outlined>
                    <span>Select Files</span>
                  </a-button>
                </a-upload>
                <div v-for="file in MTRFileList" :key="file.uid" class="file-item">
                  <span class="file-text" :title="file.name">{{ file.name }}</span>
                  <a @click="handleDownload(file)">
                    <sdFeatherIcons type="download" />
                  </a>
                  <a @click="removeFile(file)">
                    <sdFeatherIcons type="trash-2" />
                  </a>
                </div>
              </a-form-item>
            </a-col>
            <a-col :span="8">
              <a-form-item ref="InspectionReport" label="Latest Inspection Report" name="InspectionReport">
                <a-upload v-model:file-list="ReportFileList" name="InspectionReport" :multiple="true"
                  :customRequest="uploadfiles" :remove="removeFile" :style="{ width: '100%' }" :showUploadList="false">
                  <a-button class="btn-outlined" size="large" :outlined="true">
                    <upload-outlined></upload-outlined>
                    <span>Select Files</span>
                  </a-button>
                </a-upload>
                <div v-for="file in ReportFileList" :key="file.uid" class="file-item">
                  <span class="file-text" :title="file.name">{{ file.name }}</span>
                  <a @click="handleDownload(file)">
                    <sdFeatherIcons type="download" />
                  </a>
                  <a @click="removeFile(file)">
                    <sdFeatherIcons type="trash-2" />
                  </a>
                </div>
              </a-form-item>
            </a-col>
            <a-col :span="8">
              <a-form-item ref="SupplierProposal" label="Supplier’s Official Proposal" name="SupplierProposal">
                <a-upload v-model:file-list="SupplierProposalList" name="SupplierProposal" :multiple="true"
                  :customRequest="uploadfiles" :style="{ width: '100%' }" :showUploadList="false">
                  <a-button class="btn-outlined" size="large" :outlined="true">
                    <upload-outlined></upload-outlined>
                    <span>Select Files</span>
                  </a-button>
                </a-upload>
                <div v-for="file in SupplierProposalList" :key="file.uid" class="file-item">
                  <span class="file-text" :title="file.name">{{ file.name }}</span>
                  <a @click="handleDownload(file)">
                    <sdFeatherIcons type="download" />
                  </a>
                  <a @click="removeFile(file)">
                    <sdFeatherIcons type="trash-2" />
                  </a>
                </div>
              </a-form-item>
            </a-col>
            <a-row :gutter="25" v-if="productForm.quantityInitial && weight && rangeCode && totalPrice">
              <a-col :xs="24">
                <ConvertWeightAndPrice :uomCode="uomCode" :quantity="productForm.quantityInitial" :weight="weight"
                  :rangeCode="rangeCode" :totalPrice="totalPrice" />
              </a-col>
            </a-row>
            <a-col :span="24">
              <a-form-item label="Product Descirption" v-if="!productIsOther">
                <a-textarea v-model:value="productForm.note" :style="{ width: '100%' }"
                  placeholder="Product Descirption ..." name="note" class="sDash_unresizable" />
              </a-form-item>
              <sdButton :style="{ width: '100%' }" htmlType="submit" key="submit"
                class="btn-outlined ant-btn-lg client-form-submit" outlined type="primary">
                <span v-if="!productForm.editionMode">Add product to virtual stock</span>
                <span v-else>Edit product's virtual stock</span>
              </sdButton>
            </a-col>
          </a-row>
        </a-form>
      </a-drawer>
      <HistoryModal v-if="showHistoryModal" entity="Deal" :entityId="DealId" @Cancel="toggelHistory">
      </HistoryModal>
    </Main>
  </div>
</template>

<script>
import {
  FormValidationWrap,
  VerticalFormStyleWrap,
  HorizontalFormStyleWrap,
} from "../forms/overview/Style";
import { RecordViewWrapper } from "../../view/crud/axios/style";
import { Main, TableWrapper } from "../styled";
import { useStore } from "vuex";
import { defineAsyncComponent, computed, onMounted, reactive, ref, nextTick, watchEffect } from "vue";
import { useRoute, onBeforeRouteLeave } from "vue-router";
import useHsCodeSearch from "../../composable/useHsCodeSearch";
import usePartnerSearch from "../../composable/usePartnerSearch";
import { useFileDownload } from '@/composable/useFileDownload';

import moment from "moment";

const dateFormat = "YYYY/MM/DD";
const todayDate = moment();

const columns = [
  {
    title: "Product Type",
    dataIndex: "type",
    key: "type",
  },
  {
    title: "Initiale quantity ",
    dataIndex: "product.quantityInitial",
    key: "product.quantityInitial",
  },
  {
    title: "Virtuelle Quantity",
    dataIndex: "product.quantityVirtuelle",
    key: "product.quantityVirtuelle",
  },
  {
    title: "Real Quantity",
    dataIndex: "product.quantityInStock",
    key: "product.quantityInStock",
  },
  {
    title: "Total Purchase Price ",
    dataIndex: "product.priceAtCurrentLocation",
    key: "product.priceAtCurrentLocation",
  },
  {
    title: "Purchase Unit Price",
    dataIndex: "product.priceForSales",
    key: "product.priceForSales",
  },
  {
    title: "Actions",
    dataIndex: "action",
    key: "action",
    width: "90px",
  },
];
const innerOCTGColumns = [
  {
    title: "Unit of Measurement",
    dataIndex: "unitOfMeasureName",
    key: "unitOfMeasureName",
  },
  {
    title: "Size",
    dataIndex: "sizeName",
    key: "sizeName",
  },
  {
    title: "Weight",
    dataIndex: "weightName",
    key: "weightName",
  },
  {
    title: "Grade",
    dataIndex: "gradeName",
    key: "gradeName",
  },
  {
    title: "Thread",
    dataIndex: "threadName",
    key: "threadName",
  },
  {
    title: "Range",
    dataIndex: "rangeName",
    key: "rangeName",
  },
];
const innerOtherColumns = [
  {
    title: "Unit of Measurement",
    dataIndex: "unitOfMeasureName",
    key: "unitOfMeasureName",
  },
  {
    title: "HS code",
    dataIndex: "hsCodeName",
    key: "sizeName",
  },
];
const AuxiliaryCharge = defineAsyncComponent(() => import("@/components/businessComponents/AuxiliaryCharge.vue"));
const HistoryModal = defineAsyncComponent(() => import("@/components/businessComponents/HistoryModal.vue"));
const ConvertWeightAndPrice = defineAsyncComponent(() => import("@/components/converter/ConvertWeightAndPrice.vue"));

const Deal = {
  name: "Deal",
  components: {
    RecordViewWrapper,
    Main,
    TableWrapper,
    FormValidationWrap,
    VerticalFormStyleWrap,
    HorizontalFormStyleWrap,
    AuxiliaryCharge,
    HistoryModal,
    ConvertWeightAndPrice
  },
  setup() {
    const { state, dispatch } = useStore();
    const { params } = useRoute();
    const partnerApi = computed(() => state.partnerApi.data);
    const productFiles = computed(() => state.FileApi.data);
    const partnerApiLoading = computed(() => state.partnerApi.loading);
    const DealApi = computed(() => state.dealApi);
    const DealApiloading = computed(() => state.dealApi.loading);
    const dealNumber = computed(() => state.dealApi.dealNumber);

    const ProductApi = computed(() => state.ProductApi.data);
    const ProductAreLoading = computed(() => state.ProductApi.loading);
    const SizeApi = computed(() => state.SizeApi.data);
    const CategoryApi = computed(() => state.CategoryApi.data);
    const CountryApi = computed(() => state.CountryApi.data);
    const GradeApi = computed(() => state.GradeApi.data);
    const IncotermApi = computed(() => state.IncotermApi.data);
    const UnitApi = computed(() => state.UnitApi.data);
    const ThreadApi = computed(() => state.ThreadApi.data);
    const RangeApi = computed(() => state.RangeApi.data);
    const WeightApi = computed(() => state.WeightApi.data);
    const CurrencyApi = computed(() => state.CurrencyApi.data);
    const PslApi = computed(() => state.PslApi.data);
    const { downloadFile } = useFileDownload();

    const auxiliaryChargeCmp = ref(null);
    const auxiliaryTotalCharge = computed(() => {
      return auxiliaryChargeCmp.value?.totalCharge;
    });
    const PhotoFileList = computed(() =>
      state.FileApi.data.filter(
        (file) =>
          file.fileType == PictureProduct || file.fileType == "PictureProduct"
      )
    );
    const DealFileList = computed(() =>
      state.FileApi.parentFiles.filter(
        (file) => file.fileType == SupplierOffer || file.fileType == "SupplierOffer"
      )
    );
    const MTRFileList = computed(() =>
      state.FileApi.data.filter(
        (file) => file.fileType == MTR || file.fileType == "MTR"
      )
    );
    const ReportFileList = computed(() =>
      state.FileApi.data.filter(
        (file) =>
          file.fileType == InspectionReport ||
          file.fileType == "InspectionReport"
      )
    );
    const SupplierProposalList = computed(() =>
      state.FileApi.data.filter(
        (file) =>
          file.fileType == SupplierProposal ||
          file.fileType == "SupplierProposal"
      )
    );

    var formState = reactive({
      layout: "vertical",
      date: todayDate,
      validityDate: 30,
      partenaireId: null,
      number: null,
      vat: 0,
      partialSale: 1,
    });
    const { partnerData, searchPartner, partnerFetching } = usePartnerSearch(
      formState.partenaireId
    );
    var productForm = reactive({
      layout: "vertical",
      name: "",
      hsCodeId: null,
      quantityInitial: null,
      quantityVirtuelle: 0,
      quantityInStock: 0,
      priceAtCurrentLocation: null,
      priceForSales: 0,
      incoterm: "",
      deliveryTime: "",
      numberPackage: 0,
      stockLocation: "",
      stockLocationMaps: "",
      isPartialSale: 1,
      availability: 1,
      condition: 1,
      emplacementCity: "",
      emplacementCounty: "",
      note: "",
      categoryId: "",
      countryId: "",
      originCountryId: "",
      sizeId: "",
      gradeId: "",
      incotermId: "",
      pslId: "",
      weightId: "",
      rangeId: "",
      threadId: "",
      unitOfMeasureId: "",
      currencyId: "",
      offeredBy: "",
      reference: "",
      drift: "",
    });
    const OCTG = 1;
    const OTHER = 2;
    var productIsOther = ref(false);
    var readOnlyMode = ref(true);
    const productFormRef = ref([]);

    const formRef = ref();
    const PictureProduct = 1;
    const MTR = 3;
    const InspectionReport = 4;
    const SupplierProposal = 5;
    const SupplierOffer = 6;
    var SizeWeight = ref([]);

    const rules = {
      date: [
        {
          type: "object",
          required: true,
          message: "This field is required",
          trigger: "change",
        },
      ],
      validityDate: [
        {
          type: "number",
          required: true,
          message: "This field is required",
          trigger: "change",
        },
      ],
      number: [
        {
          type: "number",
          required: true,
          message: "This field is required",
          trigger: "change",
        },
      ],
      partenaireId: [
        {
          required: true,
          message: "This field is required",
          trigger: "change",
        },
      ],
    };
    const ProductRules = {
      name: [
        {
          required: true,
          message: "Please add product label",
          trigger: "change",
        },
      ],
      quantityInitial: [
        {
          type: "number",
          required: true,
          message: "Please insert quantity",
          trigger: "change",
        },
      ],
      priceAtCurrentLocation: [
        {
          type: "number",
          required: true,
          message: "Please add price",
          trigger: "change",
        },
      ],
      numberPackage: [
        {
          type: "number",
          required: true,
          message: "Please add Number of packages",
          trigger: "change",
        },
      ],
      priceForSales: [
        {
          type: "number",
          message: "Please add price",
          trigger: "change",
        },
      ],
      categoryId: [
        {
          required: true,
          message: "Please chose a category",
          trigger: "change",
        },
      ],
      countryId: [
        {
          required: true,
          message: "Please chose a country",
          trigger: "change",
        },
      ],
      sizeId: [
        {
          required: true,
          message: "Please chose size",
          trigger: "change",
        },
      ],
      gradeId: [
        {
          required: true,
          message: "Please chose grade",
          trigger: "change",
        },
      ],
      incotermId: [
        {
          required: true,
          message: "Please chose incoterm",
          trigger: "change",
        },
      ],
      weightId: [
        {
          required: true,
          message: "Please chose grade",
          trigger: "change",
        },
      ],
      rangeId: [
        {
          required: true,
          message: "Please chose grade",
          trigger: "change",
        },
      ],
      threadId: [
        {
          required: true,
          message: "Please chose grade",
          trigger: "change",
        },
      ],
      unitOfMeasureId: [
        {
          required: true,
          message: "Please chose unite",
          trigger: "change",
        },
      ],
      currencyId: [
        {
          required: true,
          message: "Please chose Currency",
          trigger: "change",
        },
      ],
    };

    onMounted(() => {
      if (params.id) {
        dispatch("singleDealGetData", params.id);
        dispatch("auxiliaryChargeGetData", `DealId=${params.id}`);
      } else {
        dispatch("dealGenerateNumber");
        readOnlyMode.value = false;
      }
      dispatch("partnerInfo");
      dispatch("sizeInfo");
      dispatch("categoryInfo");
      dispatch("countryInfo");
      dispatch("gradeInfo");
      dispatch("unitInfo");
      dispatch("threadInfo");
      dispatch("rangeInfo");
      dispatch("weightInfo");
      dispatch("currencyInfo");
      dispatch("incotermInfo");
      dispatch("pslInfo");
    });
    watchEffect(() => {
      if (DealApi.value && DealApi.value.dealId) {
        dispatch("dealProductsGetData", DealApi.value.dealId);
        formState.id = DealApi.value.data.id;
        formState.date = moment(DealApi.value.data.date);
        formState.validityDate = DealApi.value.data.validityDate;
        formState.vat = DealApi.value.data.vat;
        formState.number = Number(DealApi.value.data.number);
        fillFilesData(DealApi.value.data.files);
        if (partnerData.value.length < 1) {
          partnerData.value.push({
            value: DealApi.value.data.partenaireId,
            label: DealApi.value.data.partenaireName,
          });
        }
        formState.partenaireId = DealApi.value.data.partenaireId;
        formState.partialSale = Number(DealApi.value.data.partialSale);
        formState.offeredBy = DealApi.value.data.offeredBy;
        formState.reference = DealApi.value.data.reference;
      }

      if (dealNumber.value) {
        formState.number = Number(dealNumber.value);
      }
    });

    const productsData = computed(() =>
      ProductApi.value && ProductApi.value.length
        ? ProductApi.value.map((product, key) => {
          return {
            key: key + 1,
            type: product.productType == OCTG ? "OCTG" : "Other",
            product,
            action: (
              <div class="table-actions">
                {!readOnlyMode.value ?
                  <a-button
                    class="edit"
                    onClick={() => handleEditProduct(product)}
                  >
                    <sdFeatherIcons type="edit" size={14} />
                  </a-button> : null}
                &nbsp;&nbsp;&nbsp;
                {!readOnlyMode.value ?
                  <a-button
                    class="delete"
                    onClick={() => handleDeleteProduct(product)}
                  >
                    <sdFeatherIcons type="trash-2" size={14} />
                  </a-button> : null}
              </div>
            ),
          };
        })
        : []
    );

    const handleChangeReadMode = () => {
      return readOnlyMode.value = !readOnlyMode.value
    };
    const handleDeleteProduct = (id) => {
      const confirm = window.confirm("Are you sure delete this?");
      if (confirm) {
        dispatch("productDealApiDataDelete", id);
      }
      return false;
    };
    const handleEditProduct = (product) => {
      hsCodeData.value.push({
        value: product.hsCodeId,
        label: product.hsCodeName,
      });
      productIsOther.value = product.productType == OTHER ? true : false;
      dispatch("emptyFile");
      productForm.editionMode = true;
      productForm.id = product.id;
      productForm.quantityInitial = product.quantityInitial;
      productForm.quantityVirtuelle = product.quantityVirtuelle;
      productForm.quantityInStock = product.quantityInStock;
      productForm.priceAtCurrentLocation = product.priceAtCurrentLocation;
      productForm.priceForSales = product.priceForSales;
      productForm.incoterm = product.incoterm;
      productForm.deliveryTime = product.deliveryTime;
      productForm.numberPackage = product.numberPackage;
      productForm.stockLocation = product.stockLocation;
      productForm.stockLocationMaps = product.stockLocationMaps;
      productForm.isPartialSale = Number(product.isPartialSale);
      productForm.availability = Number(product.availability);
      productForm.condition = Number(product.condition);
      productForm.emplacementCity = product.emplacementCity;
      productForm.emplacementCounty = product.emplacementCounty;
      productForm.note = product.note;
      productForm.categoryId = product.categoryId;
      productForm.countryId = product.countryId;
      productForm.originCountryId = product.originCountryId;
      productForm.sizeId = product.sizeId;
      productForm.gradeId = product.gradeId;
      productForm.incotermId = product.incotermId;
      productForm.pslId = product.pslId;
      productForm.drift = product.drift;
      productForm.productType = product.productType;
      productForm.hsCodeId = product.hsCodeId;
      productForm.weightId = product.weightId;
      productForm.rangeId = product.rangeId;
      productForm.threadId = product.threadId;
      productForm.unitOfMeasureId = product.unitOfMeasureId;
      productForm.currencyId = product.currencyId;
      SelectWeights(productForm.sizeId);
      fillFilesData(product.files);
      uomCode.value = getCodeById(UnitApi.value, product.unitOfMeasureId);
      rangeCode.value = getCodeById(RangeApi.value, product.rangeId);
      weight.value = product.weightName;
      productForm.visible = true;
    };

    const partnerList = computed(() => {
      return partnerApi.value;
    });
    const dealFormatedDate = computed(() => {
      if (formState && formState.date) {
        return moment(formState.date).format("MMMM Do YYYY");
      }
      return "";
    });
    const sizeList = computed(() => {
      SizeApi.value && SizeApi.value.length;
      if (SizeApi.value && SizeApi.value.length) {
        return SizeApi.value.map((country, key) => {
          return {
            key: key + 1,
            label: `${country.name} - ${country.mm}mm`,
            value: country.id,
          };
        });
      } else {
        return [];
      }
    });
    const gradeList = computed(() => {
      if (GradeApi.value && GradeApi.value.length) {
        return GradeApi.value.map((country, key) => {
          return {
            key: key + 1,
            label: country.name,
            value: country.id,
          };
        });
      } else {
        return [];
      }
    });
    const incotermList = computed(() => {
      if (IncotermApi.value && IncotermApi.value.length) {
        return IncotermApi.value.map((country, key) => {
          return {
            key: key + 1,
            label: country.name,
            value: country.id,
          };
        });
      } else {
        return [];
      }
    });
    const countryList = computed(() => {
      CountryApi.value && CountryApi.value.length;
      if (CountryApi.value && CountryApi.value.length) {
        return CountryApi.value.map((country, key) => {
          return {
            key: key + 1,
            label: country.name,
            value: country.id,
          };
        });
      } else {
        return [];
      }
    });
    const DealId = computed(() => {
      if (DealApi.value) {
        return DealApi.value.dealId;
      }
      return null;
    });
    const EditionProduct = computed(() => {
      if (DealApi.value && DealApi.value.product) {
        return DealApi.value.product;
      }
      return null;
    });
    var totalPrice = computed(() => {
      return productForm.priceForSales * productForm.quantityInitial;
    });

    const onSubmitProduct = () => {
      productFormRef.value
        .validate()
        .then(async () => {
          productForm.dealId = DealId.value;
          productForm.filesNotSaved = productFiles.value;
          productForm.totalPrice = totalPrice.value;
          productForm.priceAtCurrentLocation = totalPrice.value;
          if (!productForm.editionMode) {
            dispatch("productDealSubmitData", {
              productForm,
            });
          } else {
            dispatch("productDealUpdateData", {
              productForm,
            });
          }
          await nextTick();
          productForm.editionMode = false;
          productForm.id = "";
          resetForm();
          closeDrawer();
        })
        .catch((error) => {
          console.log("error", error);
        });
    };
    const onSubmit = () => {
      formRef.value
        .validate()
        .then(async () => {
          if (!DealId.value) {
            dispatch("dealSubmitData", {
              formState,
            });
          } else {
            dispatch("dealUpdateData", {
              formState,
            });
          }
        })
        .catch((error) => {
          console.log("error", error);
        });
    };

    const showDrawer = (productType) => {
      productIsOther.value = productType == OTHER ? true : false;
      resetForm();
      productForm.visible = true;
      productForm.productType = productType;
    };
    const closeDrawer = () => {
      if (productFiles.value.length && productForm.id) {
        let files = productFiles.value;
        let id = productForm.id;
        dispatch("updateProductFiles", { files, id });
      }
      productForm.visible = false;
    };
    const resetForm = () => {
      delete productForm.id;
      productForm.editionMode = false;
      productForm.layout = "vertical";
      productForm.quantityInitial = null;
      productForm.quantityVirtuelle = 0;
      productForm.quantityInStock = 0;
      productForm.priceForSales = 0;
      productForm.incoterm = "";
      productForm.deliveryTime = "";
      productForm.numberPackage = null;
      productForm.stockLocation = "";
      productForm.stockLocationMaps = "";
      productForm.isPartialSale = 1;
      productForm.availability = 1;
      productForm.condition = 1;
      productForm.emplacementCity = "";
      productForm.emplacementCounty = "";
      productForm.note = "";
      productForm.categoryId = "";
      productForm.countryId = "";
      productForm.originCountryId = "";
      productForm.sizeId = "";
      productForm.gradeId = "";
      productForm.incotermId = "";
      productForm.pslId = "";
      productForm.drift = "";
      productForm.weightId = "";
      productForm.rangeId = "";
      productForm.threadId = "";
      productForm.unitOfMeasureId = "";
      productForm.currencyId = "";
      uomCode.value = null;
      rangeCode.value = null;
      weight.value = null;
      dispatch("emptyFile");
    };

    const uploadfiles = (file) => {
      var formData = new FormData();
      formData.append("File", file.file);
      if (productForm.id) {
        formData.append("ItemId", productForm.id);
      }
      formData.append("ItemParentId", DealApi.value.dealId);
      formData.append("ItemParentType", "Deal");
      formData.append("ItemType", "Product");

      formData.append("FileType", file.filename);
      formData.append("FileName", file.file.name);
      dispatch("fileSubmitData", {
        formData,
      });
    };
    const uploadDealfiles = (file) => {
      var formData = new FormData();
      formData.append("File", file.file);
      formData.append("ItemId", DealApi.value.dealId);
      formData.append("ItemParentId", DealApi.value.dealId);
      formData.append("ItemParentType", "Deal");
      formData.append("ItemType", "Deal");
      formData.append("FileType", file.filename);
      formData.append("FileName", file.file.name);
      dispatch("fileSubmitData", {
        formData,
      });
    };
    const fillFilesData = (files) => {
      if (files && files.length) {
        let FilesFormated = files.map((file) => {
          file.uid = file.id;
          file.name = file.fileName;
          file.url = `${process.env.VUE_APP_API_ENDPOINT}api/File/download?Id=${file.id}`;
          file.success = true;
          file.status = "done";
          return file;
        });
        if (files[0].fileType == SupplierOffer || files[0].fileType == "SupplierOffer") {
          dispatch("setParentlFiles", FilesFormated);
        } else {
          dispatch("setIntialFiles", FilesFormated);
        }
      }
    };
    const removeFile = (file) => {
      dispatch("removeFile", file);
    };
    const SelectWeights = (size) => {
      if (WeightApi.value.length) {
        SizeWeight.value = WeightApi.value
          .filter((wieght) => wieght.sizeId === size)
          .map((weight, key) => {
            return {
              key: key + 1,
              label: weight.name,
              value: weight.id,
            };
          });
      }
    };
    const selectFilterOption = (input, option) => {
      return option.label.toLowerCase().indexOf(input.toLowerCase()) >= 0;
    };
    const { searchHSCode, hsCodeData } = useHsCodeSearch(formState.HSCode);

    onBeforeRouteLeave(() => {
      dispatch('emptyDeal');
      dispatch("emptyProduct");
    });

    // history Logic
    const showHistoryModal = ref(false);
    const toggelHistory = () => {
      showHistoryModal.value = !showHistoryModal.value;
    }

    const totalCost = computed(() => {
      let totalCostSum = 0;
      for (let i = 0; i < productsData.value.length; i++) {
        const product = productsData.value[i].product;
        const quantity = product.quantityInitial;
        const unitPrice = product.price ?? product.priceAtCurrentLocation;

        totalCostSum += quantity * unitPrice;
      }

      return totalCostSum;
    });

    /// Calculation 
    const totalNet = computed(() => totalCost.value + auxiliaryTotalCharge.value)
    const totalVat = computed(() => totalNet.value * (Number(formState.vat) / 100))
    const totalTtc = computed(() => totalNet.value + totalVat.value)

    const handleDownload = async (file) => {
      // Pass the file ID to initiate download
      await downloadFile(file);
    };


    // Size converter logic 
    function getCodeById(array, id) {
      const foundObject = array.find(obj => obj.id === id);

      if (foundObject) {
        return foundObject.code;
      } else {
        // Handle the case when the object with the specified id is not found
        return null; // or throw an error, return a default value, etc.
      }
    }
    const uomCode = ref();
    const weight = ref();
    const rangeCode = ref();
    const getUomCode = (uomId) => {
      uomCode.value = getCodeById(UnitApi.value, uomId);
      if (uomCode.value == 'joint') {
        productForm.numberPackage = productForm.quantityInitial;
      }
    }
    const getWeight = (weightId) => {
      const selectedWeight = WeightApi.value.find(obj => obj.id === weightId).name
      weight.value = selectedWeight;
    }
    const getRangeCode = (rangeId) => {
      rangeCode.value = getCodeById(RangeApi.value, rangeId);
    }
    const getJointsQuantity = (value) => {
      if (uomCode.value == 'joint') {
        productForm.quantityInitial = value;
        productForm.numberPackage = value;
      }
    }


    return {
      getJointsQuantity,
      uomCode,
      weight,
      rangeCode,
      getUomCode,
      getWeight,
      getRangeCode,
      showHistoryModal,
      toggelHistory,
      partnerFetching,
      partnerData,
      searchPartner,
      hsCodeData,
      formRef,
      productFormRef,
      resetForm,
      rules,
      onSubmit,
      handleDeleteProduct,
      handleEditProduct,
      showDrawer,
      columns,
      formState,
      partnerApiLoading,
      partnerList,
      closeDrawer,
      ProductRules,
      partnerApi,
      DealApi,
      DealId,
      productForm,
      onSubmitProduct,
      ProductApi,
      productsData,
      ProductAreLoading,
      DealApiloading,
      dateFormat,
      sizeList,
      gradeList,
      incotermList,
      CategoryApi,
      CountryApi,
      UnitApi,
      ThreadApi,
      RangeApi,
      WeightApi,
      CurrencyApi,
      uploadfiles,
      MTRFileList,
      productFiles,
      removeFile,
      PhotoFileList,
      PictureProduct,
      MTR,
      ReportFileList,
      InspectionReport,
      SupplierProposal,
      SupplierProposalList,
      SizeWeight,
      fillFilesData,
      SelectWeights,
      EditionProduct,
      selectFilterOption,
      countryList,
      dealFormatedDate,
      searchHSCode,
      OCTG,
      OTHER,
      productIsOther,
      innerOCTGColumns,
      innerOtherColumns,
      totalPrice,
      handleChangeReadMode,
      readOnlyMode,
      auxiliaryChargeCmp,
      auxiliaryTotalCharge,
      totalNet,
      totalVat,
      totalTtc,
      PslApi,
      handleDownload,
      uploadDealfiles,
      DealFileList,
      SupplierOffer
    };
  },
};

export default Deal;
</script>

<style lang="scss">
#app {
  .ant-upload {
    width: 100%;
  }

  .ant-input-number-input,
  .ant-calendar-picker-input.ant-input {
    height: 38px;
  }

  .button-container {
    align-self: center;

    .ant-btn {
      height: 38px;
      width: 100%;
    }
  }

}

.deal-info {
  margin-bottom: 20px !important;
}

.inner-table table thead tr th:last-child {
  text-align: left !important;
}

.table-footer {
  text-align: right;
  font-size: 15px;
  font-weight: bold;
}

.file-item {
  display: flex;
  align-items: center;
  margin-bottom: 8px;
  margin-top: 8px;
  width: 72%;
}

.file-text {
  flex-grow: 1;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.file-item a,
.file-item span {
  margin-left: 8px;
  cursor: pointer;

  &:nth-child(2) {
    color: #1890ff !important;
  }

  &:last-child {
    color: red !important;
  }
}
</style>
